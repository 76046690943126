import React from 'react'
import { images } from 'config/images'

export const AppDevComProps = {
  ppcbannerProps: {
    heading: (
      <>
        Supporting{' '}
        <span className="highlight-head">Application Development</span> for
        Major American Companies
      </>
    ),
    para: (
      <>
        Build modern applications for tomorrow by{' '}
        <span classname="color-through">blending design thinking</span>,
        engineering, modern technology, and lean principles
      </>
    ),
    formCTA: 'Start a project with us',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Leading companies <span className="highlight-head">trust us</span> to
        build applications
      </>
    ),
    buildAppPara: (
      <>
        We help scale your teams to bring the perfect mix of talents and
        disciplines to get to the{' '}
        <span className="color-through">MVP with speed</span> and quality. After
        MVP, we follow with small releases, all of them incorporating feedback
        and real-time learning to help scale your applications.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our Key <span className="highlight-head">Services</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Mobile App Development Services',
        serviceParagraph: (
          <>
            Engineer mobile apps that provide{' '}
            <strong>unforgettable digital experiences</strong> for your target
            audience across all platforms.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'UX',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'UI UX Design and Development Services',
        serviceParagraph: (
          <>
            We blend <strong>intuitive and efficient design</strong> with
            behavioral patterns to create a delightful user experience.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Web',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'VR and AR App Development Services',
        serviceParagraph: (
          <>
            With our AR/VR development services you can leverage the{' '}
            <strong>power of extended realities</strong> and create engaging and
            interactive applications.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'API',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Cross-Platform (Hybrid) Desktop and Mobile Apps',
        serviceParagraph: (
          <>
            Our <strong>cross-platform and hybrid</strong> app development
            offering helps business' target multiple platforms with the same
            code base and save costs.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Cloud Application Development Services',
        serviceParagraph: (
          <>
            Harness cloud services like AWS, Azure and Google Cloud to build
            custom applications and{' '}
            <strong>migrate legacy architectures.</strong>
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'Quality',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Application Maintenance and Support Services',
        serviceParagraph: (
          <>
            We conduct in-depth portfolio analysis to discover growth
            opportunities in your IT architecture and{' '}
            <strong>transform existing applications</strong> if needed.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Integration',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Rapid application delivery using Agile',
        workingBenefitsContent: (
          <>
            Customers today need improved speed, quality, and reliability of
            applications. Our Agile practice helps you build{' '}
            <strong>multi-platform apps</strong> with faster iterations,
            delivery times, and with higher quality.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Microservices architecture for independent resilience',
        workingBenefitsContent: (
          <>
            We work with <strong>Microservices architecture</strong> where a
            monolithic app is split into independent units, allowing for
            continuous deployment, increased uptime, and partial updates.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'Architecture that perfectly responds to your goals',
        workingBenefitsContent: (
          <>
            <strong>Reactive architecture</strong> for creating resilient and
            flexible applications that elegantly handle ‘live’ data, high load,
            and bring impeccable UX with its high responsiveness.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Case studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What our <span className="highlight-head">customers </span> say
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/how-to-build-messaging-app-whatsapp-telegram-slack/',
        featurecardTitle:
          'How to Build a Messaging App like Whatsapp, Telegram, Slack',
      },
      {
        blogLink: 'https://www.simform.com/how-make-app-like-airbnb/',
        featurecardTitle:
          'How to Make an app like Airbnb : Develop your own Vacation Rental App',
      },
      {
        blogLink: 'https://www.simform.com/react-vs-vue/',
        featurecardTitle:
          'React vs Vue – The CTOs guide to Choosing the Right Framework',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactMichaelCullinan,
    personImg2x: images.contactMichaelCullinan2x,
    personName: 'Michel Cullian',
    personDesignation:
      'Creating UX driven software products to build a growth engine for your organization.',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
